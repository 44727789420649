// extracted by mini-css-extract-plugin
export var accordion = "Portal-module--accordion--iF3hA";
export var accordion1 = "Portal-module--accordion1--bsOy3";
export var accordion2 = "Portal-module--accordion2--2dX34";
export var body = "Portal-module--body--MHFkz";
export var body1 = "Portal-module--body1--5uGch";
export var body2 = "Portal-module--body2--9X0fq";
export var core = "Portal-module--core--5IjXW";
export var header = "Portal-module--header--iOILY";
export var header1 = "Portal-module--header1--yawsK";
export var header2 = "Portal-module--header2--65GYc";